import { useRouter } from "next/router";
import { useEffect } from "react";
import LoadingSpinner from "../components/Loading";

const Index = () => {
  const { push } = useRouter();

  useEffect(() => {
    push("login");
  }, []);

  return <LoadingSpinner />;
};

export default Index;
